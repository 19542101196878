<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import { TravelOrderCancellationStatusMap } from "@/data/trip";
import { OrderStopStatus } from "@/data/order";

export default {
  name: "ReorderOrderStops",
  components: { FacilityInfo },
  mixins: [OrdersMixin],
  props: {
    orders: Array,
    routes: Array,
    modelValue: Array,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      orderColorsList: ["primary", "success", "warning", "info", "danger"],
      orderColors: {},
    };
  },
  computed: {
    stops: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
        this.$emit("change", value);
      },
    },
    orderFreights() {
      return this.orders.flatMap((order) => order.freights);
    },
    travelOrders() {
      return this.routes.flatMap((route) => route.travelOrders);
    },
  },
  created() {
    this.orderColors = this.orders.reduce((acc, order, index) => {
      acc[order.id] = this.orderColorsList[index];
      return acc;
    }, {});
  },
  methods: {
    getStopFreights(stop) {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (stop.freights.includes(freight.id)) {
          freights.push({ ...freight, name: this.getFreightName(freight, freight.number, "", false) });
        }
      }
      return freights;
    },
    getStopTravelOrder(stop) {
      return this.travelOrders.find((to) => to.orderId === stop.order.id);
    },
    moveUpBlocked(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const previousStopIndex = stopIndex - 1;
      const previousStop = this.stops[previousStopIndex];
      const result = {
        blocked: false,
        message: null,
      };
      if (!previousStop) {
        result.blocked = true;
        return result;
      }
      if ((stop.isReload && !previousStop.isReload) || (!stop.isReload && previousStop.isReload)) {
        result.blocked = true;
        result.message = "Order and reload stops can't be swapped";
        return result;
      }
      if (previousStop.order.id === stop.order.id) {
        result.blocked = true;
        result.message = "Order stops must be from different orders";
        return result;
      }
      if (stop.status !== OrderStopStatus.notStarted || previousStop.status !== OrderStopStatus.notStarted) {
        result.blocked = true;
        result.message = "Both of the stops must have 'Not started' status";
        return result;
      }
      if (
        TravelOrderCancellationStatusMap.some((s) => s.id === this.getStopTravelOrder(stop)?.status) ||
        TravelOrderCancellationStatusMap.some((s) => s.id === this.getStopTravelOrder(previousStop)?.status)
      ) {
        result.blocked = true;
        result.message = "There is a related cancelled TO";
        return result;
      }
      return result;
    },
    moveDownBlocked(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const nextStopIndex = stopIndex + 1;
      const nextStop = this.stops[nextStopIndex];
      const result = {
        blocked: false,
        message: null,
      };
      if (!nextStop) {
        result.blocked = true;
        return result;
      }
      if ((stop.isReload && !nextStop.isReload) || (!stop.isReload && nextStop.isReload)) {
        result.blocked = true;
        result.message = "Order and reload stops can't be swapped";
        return result;
      }
      if (nextStop.order.id === stop.order.id) {
        result.blocked = true;
        result.message = "Order stops must be from different orders";
        return result;
      }
      if (stop.status !== OrderStopStatus.notStarted || nextStop.status !== OrderStopStatus.notStarted) {
        result.blocked = true;
        result.message = "Both of the stops must have 'Not started' status";
        return result;
      }
      if (
        TravelOrderCancellationStatusMap.some((s) => s.id === this.getStopTravelOrder(stop)?.status) ||
        TravelOrderCancellationStatusMap.some((s) => s.id === this.getStopTravelOrder(nextStop)?.status)
      ) {
        result.blocked = true;
        result.message = "There is a related cancelled TO";
        return result;
      }
      return result;
    },
    moveUp(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const previousStopIndex = stopIndex - 1;
      const previousStop = this.stops[previousStopIndex];
      [previousStop.position, stop.position] = [stop.position, previousStop.position];
      [this.stops[previousStopIndex], this.stops[stopIndex]] = [this.stops[stopIndex], this.stops[previousStopIndex]];
    },
    moveDown(stop) {
      const stopIndex = this.stops.findIndex((s) => s.id === stop.id);
      const nextStopIndex = stopIndex + 1;
      const nextStop = this.stops[nextStopIndex];
      [nextStop.position, stop.position] = [stop.position, nextStop.position];
      [this.stops[nextStopIndex], this.stops[stopIndex]] = [this.stops[stopIndex], this.stops[nextStopIndex]];
    },
  },
};
</script>

<template>
  <div class="py-4">
    <div class="stops-order__info d-flex align-center flex-wrap mb-6">
      <div v-for="order in orders" :key="order.id" class="stops-order__info-block d-flex align-center mr-4">
        <span :class="'square-' + orderColors[order.id]" class="stops-order__info-color rounded mr-2 d-block" />
        <span class="text-grey-darken-3 font-size-12 font-weight-medium flex-none"> Order #{{ order.number }} </span>
      </div>
    </div>
    <div class="stops-order__blocks">
      <div v-for="stop in stops" :key="stop.id" class="stops-order__block mb-6">
        <div
          :class="'border-left-' + orderColors[stop.order.id]"
          class="stops-order__block-inner pa-4 bg-grey-lighten-4 rounded"
        >
          <div class="block-header d-flex align-center justify-space-between mb-6">
            <div class="block-header__left d-flex align-center flex-wrap font-size-12">
              <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="16">
                mdi-package-variant-closed
              </v-icon>
              <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="16">
                mdi-map-marker-radius-outline
              </v-icon>
              <span class="font-weight-600 blue-text-grey-darken-1 mr-3">{{ stop.name }}</span>
              <router-link
                :to="{ name: 'order-view', params: { id: stop.order.id } }"
                class="d-block text-primary font-weight-500 font-size-12 text-decoration-none"
                data-qa="route-stops-order-number"
                target="_blank"
              >
                Order #{{ stop.order.number }}
              </router-link>
            </div>
            <div class="block-header__right d-flex flex-column">
              <v-tooltip :disabled="!moveUpBlocked(stop).message" location="top">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      :disabled="moveUpBlocked(stop).blocked"
                      icon="mdi-chevron-up"
                      size="xs"
                      variant="plain"
                      @click="moveUp(stop)"
                    ></v-btn>
                  </div>
                </template>
                {{ moveUpBlocked(stop).message }}
              </v-tooltip>

              <v-tooltip :disabled="!moveDownBlocked(stop).message" location="top">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      :disabled="moveDownBlocked(stop).blocked"
                      icon="mdi-chevron-down"
                      size="xs"
                      variant="plain"
                      @click="moveDown(stop)"
                    ></v-btn>
                  </div>
                </template>
                {{ moveDownBlocked(stop).message }}
              </v-tooltip>
            </div>
          </div>
          <facility-info :facility="stop.facility" :updated="false" class="mb-5"></facility-info>
          <div class="d-flex align-center mb-6">
            <v-icon class="mr-2 text-grey-darken-1" size="17">mdi-clock-outline</v-icon>
            <span class="body-2 text-grey-darken-1 font-weight-medium">
              {{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}
            </span>
          </div>
          <div class="d-flex">
            <div class="text-grey-darken-1 mr-2">Freights:</div>
            <div class="font-weight-500 text-grey-darken-2" data-qa="freight-size">
              <div v-for="freight in getStopFreights(stop)" :key="freight.id">
                {{ freight.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/assets/style/color" as *;

.stops-order__info-color {
  width: 16px;
  height: 16px;
}

.square {
  &-primary {
    background-color: #536dfe !important;
  }

  &-success {
    background-color: #7cb342 !important;
  }

  &-warning {
    background-color: #ffab40 !important;
  }

  &-info {
    background-color: #ab47bc !important;
  }

  &-danger {
    background-color: #ff7043 !important;
  }
}

.stops-order__block-inner {
  border-left: 6px solid;

  &.border-left-primary {
    border-color: #536dfe !important;
  }

  &.border-left-success {
    border-color: #7cb342 !important;
  }

  &.border-left-warning {
    border-color: #ffab40 !important;
  }

  &.border-left-info {
    border-color: #ab47bc !important;
  }

  &.border-left-danger {
    border-color: #ff7043 !important;
  }
}
</style>
