<script>
import UsersMixin from "@/views/users/UsersMixin.vue";
import { VInput } from "vuetify/components";
import ValidationMixin from "@/mixins/validation.mixin";

export default {
  name: "CitizenshipSelect",
  extends: VInput,
  mixins: [UsersMixin, ValidationMixin],
  props: {
    modelValue: String,
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      rulesInternal: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  beforeMount() {
    if (this.required) {
      this.rulesInternal.push(this.requiredValidator);
    }
    this.rulesInternal.push(...this.rules);
  },
};
</script>

<template>
  <v-select
    id="citizenship"
    v-model="model"
    :class="{ required: required }"
    :items="citizenships"
    :label="$t('drivers.citizenship')"
    :rules="rulesInternal"
    color="primary"
    density="compact"
    hide-details="auto"
    item-title="name"
    item-value="id"
    variant="outlined"
  />
</template>
