import { OrderStopType } from "@/data/order";
import type { TripOrderStop } from "@/views/trips/edit/trip";

class OrderValidator {
  #isValid = true;
  #order;

  #fieldRequiredError = "Field is required";
  #stopTimeError = "The time at the stop is indicated earlier than at the previous stop.";
  #unusedFreightError =
    "Freight not used at any delivery stop. Please select freight on delivery stop or delete freight";
  #lastStopError = "Pick up can’t be the last stop in the order. Please change the order of the stops";

  constructor(order: any) {
    this.#order = order;
  }

  validate() {
    this.#validateSendUpdatesTo();
    this.#validateLastStop();
    this.#validateUnusedFreights();
    this.#validateStopsTime();

    return this.#isValid;
  }

  #validateStopsTime() {
    this.#order.orderStops.reduce((lastTime: number, stop: any) => {
      const dateTo = Date.parse(stop.timeTo);
      stop.$timeError = null;
      if (dateTo < lastTime) {
        stop.$timeError = this.#stopTimeError;
        this.#isValid = false;
      }
      return dateTo;
    }, 0);
  }

  #validateUnusedFreights() {
    const deliveryFreights = this.#order.orderStops
      .filter((s: any) => s.type === OrderStopType.delivery)
      .flatMap((s: any) => s.freights);
    const pickupFreights = this.#order.orderStops
      .filter((s: any) => s.type === OrderStopType.pickup)
      .flatMap((s: any) => s.freights);
    this.#order.freights.forEach((f: any) => (f.$error = null));
    const unusedFreights = pickupFreights.filter((f: any) => !deliveryFreights.includes(f));
    if (unusedFreights.length > 0) {
      this.#order.freights
        .filter((f: any) => unusedFreights.includes(f.id))
        .forEach((f: any) => {
          f.$error = this.#unusedFreightError;
        });
      this.#isValid = false;
    }
  }

  #validateLastStop() {
    this.#order.orderStops.forEach((s: any) => {
      s.$error = null;
    });
    const lastStop = this.#order.orderStops.sort(
      (a: TripOrderStop, b: TripOrderStop) => (a.position || 0) - (b.position || 0),
    )[this.#order.orderStops.length - 1];
    if (lastStop?.type !== OrderStopType.delivery) {
      lastStop.$error = this.#lastStopError;
      this.#isValid = false;
    }
  }

  #validateSendUpdatesTo() {
    this.#order.sendUpdatesTo.$error = null;
    if (this.#order.sendUpdatesTo.length === 0) {
      this.#order.sendUpdatesTo.$error = this.#fieldRequiredError;
      this.#isValid = false;
    }
  }
}

export default OrderValidator;
