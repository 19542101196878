<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import OrderStopStatus from "@/components/status/OrderStopStatus.vue";
import { OrderFreightState, OrderStopState, OrderStopType } from "@/data/order";
import StopChangesPopover from "@/views/trips/components/StopChangesPopover.vue";
import FacilityInfo from "@/views/trips/components/FacilityInfo.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import OrderStopFreightDetails from "@/views/trips/components/OrderStopFreightDetails.vue";

export default {
  name: "OrderStopBlock",
  components: {
    OrderStopFreightDetails,
    AlertMessage,
    FacilityInfo,
    StopChangesPopover,
    OrderStopStatus,
  },
  mixins: [OrdersMixin],
  props: {
    orderFreights: {
      type: Array,
    },
    routes: {
      type: Array,
    },
    stop: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["change", "delete"],
  computed: {
    freights() {
      const freights = [];
      for (const freight of this.orderFreights) {
        if (this.stop.freights.includes(freight.id)) {
          freights.push({ ...freight, name: this.getFreightName(freight, freight.number, "", false) });
        }
      }
      return freights;
    },
    isPickup() {
      return this.stop.type === OrderStopType.pickup;
    },
    isUpdated() {
      return (
        this.stop.state === OrderStopState.updated ||
        this.freights.some((f) => f.state !== OrderFreightState.normal || f.isDeleted)
      );
    },
    mismatch() {
      const routeFreights = this.routes.flatMap((r) => r.freights);
      if (!routeFreights || !this.freights || this.stop.type === OrderStopType.delivery) {
        return null;
      }
      const notAllocatedFreights = [];
      const mismatchFreights = [];
      for (const orderStopFreight of this.freights) {
        const allocatedRouteFreights = routeFreights.filter((f) => f.freightId === orderStopFreight.id);
        if (allocatedRouteFreights.length === 0) {
          notAllocatedFreights.push(orderStopFreight);
        } else {
          const allocatedQuantity = allocatedRouteFreights.reduce((acc, f) => acc + parseInt(f.plannedQuantity), 0);
          const allocatedWeight = allocatedRouteFreights.reduce((acc, f) => acc + parseInt(f.plannedWeight), 0);
          if (allocatedQuantity !== orderStopFreight.quantity || allocatedWeight !== orderStopFreight.weight) {
            const mismatchChunks = [];
            const quantityDiff = allocatedQuantity - orderStopFreight.quantity;
            const weightDiff = allocatedWeight - orderStopFreight.weight;
            if (quantityDiff > 0) {
              mismatchChunks.push(`selected additional ${quantityDiff} ${orderStopFreight.type}`);
            }
            if (quantityDiff < 0) {
              mismatchChunks.push(`not selected ${-quantityDiff} ${orderStopFreight.type}`);
            }
            if (weightDiff > 0) {
              mismatchChunks.push(`selected additional ${weightDiff} lbs`);
            }
            if (weightDiff < 0) {
              mismatchChunks.push(`not selected ${-weightDiff} lbs`);
            }
            mismatchFreights.push({
              freightNumber: orderStopFreight.number,
              diff: mismatchChunks.join(", "),
            });
          }
        }
      }

      return {
        notAllocatedFreights,
        mismatchFreights,
      };
    },
    deletedFreights() {
      return this.freights.filter((f) => f.isDeleted);
    },
  },
  methods: {
    getFreightClass(freight) {
      return {
        "freight--new": freight.state === OrderFreightState.new,
        "freight--updated": freight.state === OrderFreightState.updated,
        "freight--deleted": freight.isDeleted,
      };
    },
  },
};
</script>

<template>
  <div :class="{ 'reload-stop': stop.type === STOP_RELOAD, 'updated-stop': isUpdated }" class="trip-stops__block">
    <div :class="disabled ? 'bg-red-lighten-5' : 'bg-grey-lighten-4'" class="trip-stops__block-inner pa-4 rounded">
      <div class="mb-6 trip-stops__block-header">
        <div class="d-flex align-center justify-space-between mb-2">
          <div class="d-flex align-center flex-wrap">
            <v-icon v-if="stop.type === STOP_PICK_UP" class="mr-2" color="green" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_RELOAD" class="mr-2" color="orange" size="20">
              mdi-package-variant-closed
            </v-icon>
            <v-icon v-if="stop.type === STOP_DELIVERY" class="mr-2" color="red accent-2" size="20">
              mdi-map-marker-radius-outline
            </v-icon>
            <span class="blue-text-grey-darken-1 font-weight-600 mr-3 font-size-12" data-qa="stop-type">
              {{ stop.name }}
            </span>
            <router-link
              v-if="stop.order"
              :to="{ name: 'order-view', params: { id: stop.order.orderId || stop.order.id } }"
              class="font-size-12 font-weight-medium text-primary text-decoration-none"
              target="_blank"
            >
              Order #{{ stop.order.number }}
            </router-link>
            <stop-changes-popover
              v-if="isUpdated"
              :freights="freights"
              :stop="stop"
              label="Order stop changes"
            ></stop-changes-popover>
          </div>
          <div v-if="isUpdated" class="badge bg-warning pa-2 rounded-te rounded-bs font-weight-600">UPDATED</div>
        </div>

        <order-stop-status :stop="stop"></order-stop-status>
      </div>

      <div class="mb-6 trip-stops__block-body">
        <facility-info
          :facility="stop.facility"
          :updated="isUpdated && !!stop.oldFacilityId && !disabled"
          class="mb-5"
        ></facility-info>
        <div v-if="stop.timeType && !stop.isReload" class="d-flex align-center text-grey-darken-1">
          <v-icon class="mr-2" size="20">mdi-clock-outline</v-icon>
          <span class="font-weight-medium" data-qa="stop-time"
            >{{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}</span
          >
        </div>
      </div>
      <div class="trip-stops__block-footer">
        <div class="d-flex">
          <div class="text-grey-darken-1 mr-2">Freights:</div>
          <div
            :class="disabled ? 'text-error' : 'text-grey-darken-2'"
            class="font-weight-medium"
            data-qa="freight-size"
          >
            <div v-for="freight in freights" :key="freight.id" :class="getFreightClass(freight)">
              {{ freight.name }}
            </div>
          </div>
        </div>
        <template v-if="mismatch?.notAllocatedFreights.length > 0">
          <alert-message v-for="freight in mismatch.notAllocatedFreights" :key="freight.id" class="mt-3">
            Add Freight #{{ freight.number }} to any PU in route(s)
          </alert-message>
        </template>
        <template v-if="mismatch?.mismatchFreights.length > 0">
          <alert-message
            v-for="mismatchData in mismatch.mismatchFreights"
            :key="mismatchData.freightNumber"
            class="mt-3"
            data-qa="updated-freight-data-message"
          >
            Freight #{{ mismatchData.freightNumber }} mismatch: {{ mismatchData.diff }}
          </alert-message>
        </template>
        <alert-message
          v-for="deletedFreight in deletedFreights"
          :key="deletedFreight.id"
          bg-color="indigo-lighten-5"
          border-color="none"
          class="mt-3"
          color="indigo"
          icon="mdi-information-outline"
          icon-color="indigo"
        >
          Freight #{{ deletedFreight.number }} will be removed after re-dispatch confirmation
        </alert-message>
        <order-stop-freight-details v-if="isPickup" :freights="freights"></order-stop-freight-details>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.trip-stops__block-inner {
  height: 100%;
}

.updated-stop .trip-stops__block-inner {
  border: 1px solid $orange;
}

.badge {
  margin-top: -16px;
  margin-right: -16px;
}

.freight--deleted {
  text-decoration-line: line-through;
  text-decoration-color: $grey-lighter-contrast;
  color: $grey-lighter-contrast !important;

  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $grey-lighter-contrast;
  }
}

.freight--updated {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $orange;
  }
}

.freight--new {
  &:after {
    position: absolute;
    content: "•";
    font-size: 20px;
    text-decoration: none !important;
    color: $green;
  }
}
</style>
