<script>
import { OrderStopState, OrderStopStateMap } from "@/data/order.ts";

export default {
  name: "OrderStopState",
  props: {
    stop: { type: Object, required: true },
    state: String,
  },
  computed: {
    stopState() {
      return this.state || this.stop.state;
    },
    classes() {
      if (this.stop.isDeleted) {
        return "bg-grey-darken-2 text-white";
      }
      switch (this.stopState) {
        case OrderStopState.normal:
          return "d-none";
        case OrderStopState.updated:
          return "bg-warning text-white";
        case OrderStopState.new:
          return "bg-green-lighten-1 text-white";
        default:
          return "bg-grey-lighten-2 text-white";
      }
    },
    label() {
      if (this.stop.isDeleted) {
        return "Deleted";
      }
      return OrderStopStateMap.find((s) => s.id === this.stopState)?.name || "Unknown";
    },
  },
};
</script>

<template>
  <div :class="classes" class="status-label rounded pa-2">
    {{ label }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}
</style>
