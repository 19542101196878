<script lang="ts" setup>
import { defineProps } from "vue";
import AlertMessage from "@/components/AlertMessage.vue";
import { OrderStopState } from "@/data/order";
import type { OrderDto } from "@/api/orders/dto/order.dto";

const { orders } = defineProps<{
  orders: OrderDto[];
}>();

const emit = defineEmits<{
  (e: "add:route"): void;
  (e: "add:order"): void;
  (e: "edit:stopsOrder"): void;
}>();

function isOrderChanged(order: OrderDto): boolean {
  return order.orderStops.some((os) => os.state !== OrderStopState.normal);
}

function addRoute(): void {
  emit("add:route");
}

function addOrder(): void {
  emit("add:order");
}

function editStopsOrder(): void {
  emit("edit:stopsOrder");
}
</script>

<template>
  <div class="trip-block mb-4" data-qa="trip-block-main">
    <div class="trip-block__inner bg-white pa-4 rounded-lg">
      <div class="trip-block__header d-flex align-center justify-space-between mb-3">
        <span class="text-grey-darken-2 font-weight-medium">Trip</span>
        <v-menu offset-y>
          <template #activator="{ props }">
            <v-btn elevation="0" icon="mdi-dots-horizontal" size="x-small" v-bind="props" />
          </template>
          <v-list>
            <v-list-item @click="addRoute">
              <v-list-item-title data-qa="add-route">Add route</v-list-item-title>
            </v-list-item>
            <v-list-item @click="addOrder">
              <v-list-item-title data-qa="add-order">Add order</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="orders.length > 1" @click="editStopsOrder">
              <v-list-item-title data-qa="edit-stops-order">Edit stops order</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <template v-for="order in orders" :key="order.id">
        <alert-message v-if="isOrderChanged(order)" bg-color="grey-lighten-4" data-qa="update-order-message">
          Order #{{ order.number }} has been changed, please check details below
        </alert-message>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.trip-block__inner {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
