<script>
import OrdersMixin from "@/views/orders/OrdersMixin.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import ValidationMixin from "@/mixins/validation.mixin";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";
import OmniTextarea from "@/components/inputs/Textarea.vue";
import OmniTextField from "@/components/controls/OmniTextField.vue";
import StopDateRange from "@/views/orders/components/StopDateRange.vue";
import OmniDialog from "@/components/OmniDialog.vue";
import { customAlphabet } from "nanoid";
import { OrderFreightState, OrderStopType } from "@/data/order";
import AlertMessage from "@/components/AlertMessage.vue";
import { RouteStopStatus } from "@/data/trip";

export default {
  name: "EditStopDialog",
  components: { AlertMessage, OmniDialog, StopDateRange, OmniTextField, OmniTextarea },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin, GoogleMapsMixin],
  props: {
    modelValue: Boolean,
    stop: { type: Object, required: true },
    orderStops: { type: Array, required: true },
    routeFreights: { type: Array, required: true },
    unusedRouteFreights: { type: Array, required: true },
    orderFreights: { type: Array, required: true },
    allowEditUsage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "changed"],
  data() {
    return {
      stopRouteFreights: [],
      stopData: {},
    };
  },
  computed: {
    OrderFreightState() {
      return OrderFreightState;
    },
    showDialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    orderStop() {
      return this.orderStops.find((os) => os.id === this.stop.orderStopId);
    },
    facilityAddress() {
      const chunks = [];
      if (this.stop.facility.addressLine) {
        chunks.push(this.stop.facility.addressLine);
      }
      if (this.stop.facility.address) {
        chunks.push(this.stop.facility.address);
      }
      return chunks.join(", ");
    },
    orderFreightsWithName() {
      return this.orderFreights
        .filter((f) => this.orderStop.freights.includes(f.id))
        .map((f) => ({
          ...f,
          name: this.getFreightName(f, f.number, "", false),
        }));
    },
    isPickup() {
      return this.stop.type === OrderStopType.pickup;
    },
    isDelivery() {
      return this.stop.type === OrderStopType.delivery;
    },
    quantityLabel() {
      return this.isPickup ? "Planned quantity" : "Quantity";
    },
    weightLabel() {
      return this.isPickup ? "Planned total weight" : "Total weight";
    },
    changeDisabled() {
      return this.isPickup && ![RouteStopStatus.notStarted, RouteStopStatus.inRoute].includes(this.stop.status);
    },
    saveDisabled() {
      return this.isPickup && this.stopRouteFreights.length === 0;
    },
  },
  watch: {
    modelValue(value) {
      if (!value) {
        this.stopRouteFreights = [];
        this.stopData = {};
      } else {
        this.stopRouteFreights = this.stop.freights.map((f) => ({ ...this.routeFreights.find((rf) => rf.id === f) }));
        this.stopData = {
          timeType: this.stop.timeType,
          timezone: this.stop.timezone,
          timeFrom: this.stop.timeFrom,
          timeTo: this.stop.timeTo,
          time2Type: this.stop.time2Type,
          time2From: this.stop.time2From,
          time2To: this.stop.time2To,
        };
      }
    },
  },
  methods: {
    getOrderStopsByFreight(freight) {
      return this.orderStops.filter((os) => os.freights.includes(freight?.id));
    },
    getOrderByFreight(freight) {
      return this.getOrderStopsByFreight(freight)[0].order;
    },
    getFreightPoints(freight) {
      const orderStops = this.getOrderStopsByFreight(freight);
      return orderStops.map((os) => ({ ...os.facility, fullAddress: os.facility.address }));
    },
    getRouteFreightsForOrderFreight(orderFreight) {
      return this.routeFreights
        .filter((rf) => rf.freightId === orderFreight.id)
        .map((rf, i) => {
          rf.number = i + 1;
          return rf;
        });
    },
    getStopRouteFreightsForOrderFreight(orderFreight) {
      return this.stopRouteFreights
        .filter((rf) => rf.freightId === orderFreight.id)
        .map((rf, i) => {
          rf.number = i + 1;
          return rf;
        });
    },
    addFreight(orderFreight) {
      this.stopRouteFreights.push({
        id: this.generateId(),
        freightId: orderFreight.id,
        quantity: null,
        weight: null,
        stackable: orderFreight.stackable,
        type: orderFreight.type,
        plannedWeight: 1,
        plannedQuantity: 1,
        $isNew: true,
      });
    },
    isSelected(routeFreight) {
      return this.stopRouteFreights.map((rf) => rf.id).includes(routeFreight.id);
    },
    selectFreight(routeFreight, value) {
      if (value) {
        this.stopRouteFreights.push(routeFreight);
      } else {
        this.stopRouteFreights = this.stopRouteFreights.filter((rf) => rf.id !== routeFreight.id);
      }
    },
    generateId() {
      const nanoid = customAlphabet("1234567890abcdef", 16);
      return nanoid();
    },
    async save(useStop = true) {
      const { valid } = await this.$refs.editStopForm.validate();
      if (!valid) return;
      const stop = { ...this.stop, ...this.stopData, use: useStop, freights: this.stopRouteFreights.map((f) => f.id) };
      this.$emit("changed", stop, this.stopRouteFreights);
      this.showDialog = false;
    },
    remove() {
      this.save(false);
    },
  },
};
</script>

<template>
  <omni-dialog
    v-model="showDialog"
    :disabled="saveDisabled"
    :primary-action-label="allowEditUsage && !stop.use ? 'Add' : 'Save'"
    :secondary-action-label="allowEditUsage && stop.use ? 'Delete stop' : null"
    :title="'Edit route stop - ' + stop.name"
    width="830"
    @primary-action:click="save"
    @secondary-action:click="remove"
  >
    <v-form ref="editStopForm">
      <div class="mb-4">
        <div class="text-grey-darken-1 mb-1">Facility</div>
        <div class="text-grey-darken-3 text-uppercase font-size-16">{{ stop.facility.name }}</div>
      </div>
      <div class="bg-grey-lighten-5 pa-3 rounded mb-6">
        <div class="text-grey-darken-3 font-size-16 mb-2">{{ facilityAddress }}</div>
        <div class="d-flex align-center mb-6">
          <v-icon class="mr-2" color="green" size="16" small> mdi-map-marker-radius-outline</v-icon>
          <a
            :href="getPlaceUrl(stop.facility.addressCoordinates)"
            class="text-green caption text-decoration-underline"
            target="_blank"
          >
            Lat: {{ stop.facility.addressCoordinates.lat }} Lon: {{ stop.facility.addressCoordinates.lon }}
          </a>
        </div>
        <omni-textarea label="Facility note" readonly></omni-textarea>
      </div>
      <div class="mb-6">
        <div class="text-grey-darken-3 font-size-16 font-weight-500">Freight(s) from order:</div>
        <div
          v-for="orderFreight in orderFreightsWithName"
          :key="orderFreight.id"
          :class="{
            'freight--deleted': orderFreight.isDeleted,
            'freight--new': orderFreight.state === OrderFreightState.new,
            'freight--updated': orderFreight.state === OrderFreightState.updated,
          }"
          class="freight mt-4 rounded-b"
        >
          <div class="bg-indigo-lighten-5 freight--header pa-4">
            <div class="d-flex align-center mb-2">
              <div class="text-grey-darken-3 font-weight-500 font-size-16 freight--name">{{ orderFreight.name }}</div>
              <span v-if="orderFreight.isDeleted" class="text-grey font-weight-500 ml-4">
                Order #{{ getOrderByFreight(orderFreight)?.number }}
              </span>
              <router-link
                v-else
                :to="{ name: 'order-view', params: { id: getOrderByFreight(orderFreight)?.id } }"
                class="text-decoration-none text-primary font-weight-500 ml-4"
                target="_blank"
              >
                Order #{{ getOrderByFreight(orderFreight)?.number }}
              </router-link>
            </div>
            <div class="text-grey-darken-1 d-flex align-center freight--points">
              <template v-for="(point, i) in getFreightPoints(orderFreight)" :key="point.id">
                {{ point.fullAddress }}
                <v-icon
                  v-if="i < getFreightPoints(orderFreight).length - 1"
                  class="mx-1"
                  icon="mdi-arrow-right"
                  size="14"
                ></v-icon>
              </template>
            </div>
          </div>
          <div v-if="isPickup" class="bg-grey-lighten-5 freight--form pa-4 rounded-b">
            <alert-message
              v-if="orderFreight.isDeleted"
              bg-color="indigo-lighten-5"
              border-color="none"
              class="mb-4"
              color="indigo"
              icon="mdi-information-outline"
              icon-color="indigo"
            >
              Freight#{{ orderFreight.number }} will be removed after re-dispatch confirmation
            </alert-message>
            <alert-message v-else-if="orderFreight.state === OrderFreightState.updated" class="mb-4">
              Please update route freight information
            </alert-message>
            <div class="text-grey-darken-2 mb-4 freight--add-label">Add route freight for order freight:</div>
            <template v-for="routeFreight in getStopRouteFreightsForOrderFreight(orderFreight)" :key="routeFreight.id">
              <div class="d-flex align-center text-grey-darken-3 font-weight-500 mb-4 freight--route-freight-name">
                <span>Route freight #{{ routeFreight.number }}:</span>
                <div
                  v-if="orderFreight.isDeleted"
                  class="rounded text-uppercase text-white bg-grey-darken-2 px-2 font-size-11 font-weight-600 ml-2"
                >
                  Deleted
                </div>
              </div>
              <v-row class="mb-3">
                <v-col>
                  <omni-text-field
                    v-model="routeFreight.plannedQuantity"
                    :disabled="orderFreight.isDeleted || changeDisabled"
                    :label="quantityLabel"
                    :rules="[requiredValidator, positiveNumber]"
                    data-qa="modal-planned-quantity"
                    min="0"
                    required
                    type="number"
                  ></omni-text-field>
                </v-col>
                <v-col>
                  <omni-text-field
                    v-model="routeFreight.plannedWeight"
                    :disabled="orderFreight.isDeleted || changeDisabled"
                    :label="weightLabel"
                    :rules="[requiredValidator, positiveNumber]"
                    data-qa="modal-planned-weight"
                    min="0"
                    required
                    suffix="lbs"
                    type="number"
                  ></omni-text-field>
                </v-col>
              </v-row>
            </template>
            <v-btn
              :disabled="orderFreight.isDeleted || changeDisabled"
              class="px-2"
              data-qa="add-route-freight-button"
              density="compact"
              variant="text"
              @click="addFreight(orderFreight)"
            >
              <div class="d-flex align-center text-primary text-uppercase font-size-11 font-weight-600">
                <v-icon class="mr-2" icon="mdi-plus"></v-icon>
                Add route freight
              </div>
            </v-btn>
          </div>
          <div v-if="isDelivery" class="bg-grey-lighten-5 pa-4 rounded-b freight-border">
            <div class="text-grey-darken-2 mb-4">Select route freight(s) to be delivered to this stop:</div>
            <div v-for="routeFreight in getRouteFreightsForOrderFreight(orderFreight)" :key="routeFreight.id">
              <v-checkbox
                :disabled="!routeFreight.$isNew"
                :label="'Route freight ' + getFreightName(routeFreight, routeFreight.number, '', false, true)"
                :model-value="isSelected(routeFreight)"
                color="primary"
                density="compact"
                hide-details
                @update:model-value="(val) => selectFreight(routeFreight, val)"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex align-center text-grey-darken-1 font-weight-500 mb-4">
          <v-icon class="mr-2" icon="mdi-clock-outline" size="16"></v-icon>
          Order stop time: {{ timeTypesMap[stop.timeType] }}: {{ getStopTime(stop) }}
        </div>
        <stop-date-range v-model="stopData" :disabled="changeDisabled" multiline></stop-date-range>
      </div>
    </v-form>
  </omni-dialog>
</template>

<style lang="scss" scoped>
@import "@/assets/style/color";

.freight {
  &.freight--deleted {
    .freight--header,
    .freight--form {
      background-color: $grey-input-bg !important;
    }

    .freight--name,
    .freight--points,
    .freight--add-label,
    .freight--route-freight-name {
      color: $grey-lighter-contrast !important;
    }

    .freight--name {
      position: relative;

      &:after {
        position: absolute;
        content: "•";
        font-size: 20px;
        text-decoration: none !important;
        color: $grey-light;
      }
    }
  }

  &.freight--new {
    border: 1px solid $green;

    .freight--header {
      background-color: $green-light !important;
    }

    .freight--name {
      position: relative;

      &:after {
        position: absolute;
        content: "•";
        font-size: 20px;
        text-decoration: none !important;
        color: $green;
      }
    }
  }

  &.freight--updated {
    border: 1px solid $orange;

    .freight--header {
      background-color: $orange-light !important;
    }

    .freight--name {
      position: relative;

      &:after {
        position: absolute;
        content: "•";
        font-size: 20px;
        text-decoration: none !important;
        color: $orange;
      }
    }
  }
}
</style>
