<script lang="ts" setup>
import UpdateIndicator from "@/views/trips/components/UpdateIndicator.vue";
import type { OrderFacilityDto } from "@/api/orders/dto/order.dto";
import { computed } from "vue";
import { FacilityType } from "@/data/facility";
import GoogleMapsMixin from "@/mixins/google-maps.mixin";

const props = withDefaults(
  defineProps<{
    facility: OrderFacilityDto;
    updated?: boolean;
    disabled?: boolean;
  }>(),
  {
    updated: false,
    disabled: false,
  },
);

const fullAddress = computed(() => {
  const chunks: string[] = [];
  if (props.facility.addressLine) {
    chunks.push(props.facility.addressLine);
  }
  if (props.facility.address) {
    chunks.push(props.facility.address);
  }
  return chunks.join(", ");
});

const getFacilityMapUrl = (facility: OrderFacilityDto): string => {
  const gmApi = GoogleMapsMixin.methods;
  if (facility.type === FacilityType.point && facility.preciseCoordinates) {
    return gmApi.getPlaceUrl(facility.preciseCoordinates);
  }
  return gmApi.getPlaceUrlByAddress(
    facility.addressLine ? facility.addressLine + ", " + facility.address : facility.address,
  );
};
</script>

<template>
  <div>
    <div class="d-flex align-center mb-2">
      <div v-if="disabled" class="text-uppercase subheading font-weight-medium text-grey">
        {{ props.facility.name }}
      </div>
      <template v-else>
        <router-link
          :to="{ name: 'facilities-edit', params: { id: props.facility.id } }"
          class="text-uppercase subheading font-weight-medium text-primary d-block text-decoration-none"
          data-qa="facility-name"
          target="_blank"
        >
          {{ props.facility.name }}
        </router-link>
        <update-indicator v-if="props.updated" class="ml-1"></update-indicator>
      </template>
    </div>
    <div class="d-flex align-center">
      <template v-if="disabled">
        <div class="text-grey">
          {{ fullAddress }}
        </div>
        <v-icon class="ml-2" color="grey" icon="mdi-map-marker-radius-outline"></v-icon>
      </template>
      <template v-else>
        <div class="text-grey-darken-2" data-qa="facility-address">
          {{ fullAddress }}
        </div>
        <a :href="getFacilityMapUrl(facility)" class="ml-2" target="_blank">
          <v-icon color="success" icon="mdi-map-marker-radius-outline"></v-icon>
        </a>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
