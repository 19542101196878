<script>
import { OrderStopStatus, OrderStopStatusMap } from "@/data/order";

export default {
  name: "OrderStopStatus",
  props: {
    stop: { type: Object, required: true },
    hideEdgeStatuses: Boolean,
  },
  computed: {
    visible() {
      return this.hideEdgeStatuses
        ? ![OrderStopStatus.notStarted, OrderStopStatus.completed].includes(this.stop.status)
        : true;
    },
    classes() {
      if (this.stop.isDeleted) {
        return "text-grey";
      }
      switch (this.stop.status) {
        case OrderStopStatus.inProgress:
        case OrderStopStatus.partiallyCompleted:
          return "bg-amber-lighten-2 text-grey-darken-3";
        case OrderStopStatus.completed:
          return "bg-green-lighten-1 text-white";
        default:
          return "bg-grey-lighten-4 text-grey-darken-3";
      }
    },
    label() {
      return OrderStopStatusMap.find((s) => s.id === this.stop.status)?.name || "Unknown";
    },
  },
};
</script>

<template>
  <div v-if="visible" :class="classes" class="status-label rounded-lg pr-2 pl-2">
    {{ label }}
  </div>
</template>

<style scoped>
.status-label {
  white-space: nowrap;
  display: inline-block;
  line-height: 16px !important;
  font-size: 11px;
}
</style>
